.MuiDialog-root {
  .MuiDialog-container {
    .MuiDialog-paper {
      .MuiDialogTitle-root {
        background-color: var(--dialog-header-background) !important;
        color: var(--dialog-header-background);
        text-align: left;
        height: fit-content;
        padding-left: 14px;

        .MuiTypography-root {
          color: var(--ion-text-light) !important;
          font-weight: 600;
          font-size: 20px;
          line-height: 29px;
          font-family: Jost !important;
          margin-right: 14px;
        }
      }

      .MuiIconButton-root {
        position: absolute;
        top: 18px;
        // right: 12px;
        opacity: 1;
        background-color: var(--dialog-header-background) !important;
        color: var(--ion-text-light) !important;
      }

      .MuiDialogContent-root {
        background: var(--dialog-content-background);
        // padding: 0px 16px !important;

        .MuiButton-containedPrimary {
          background-color: var(--ion-color-primary);
          color: var(--ion-text-light) !important;
          text-transform: none;
          font-weight: 600;
        }

        .MuiButton-containedPrimary:hover {
          background-color: var(--ion-color-primary-shade);
        }
      }
    }
  }
}
