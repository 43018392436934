.modal-close-btn {
  position: absolute !important;
  top: 8px;
  right: 8px;
  color: #9e9e9e;
}
.modal-title-ctn {
  background-color: aqua !important;
  // margin-right: 8px !important;
}
